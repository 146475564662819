.privacy-policy {
  padding: 1.8rem;
  font-size: 1rem;
  color: #151515;
}

.terms-of-use {
  padding: 1.8rem;
  font-size: 1rem;
  color: #151515;
  line-height: 1.2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 790px;
  margin: 0 auto;

  h1 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    margin-bottom: 56px;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin: 1rem 0 1rem 1rem;

    li:not(last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__section-header {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 48px 0 24px 0;
    font-weight: 600;
  }

  &__li-no-style {
    list-style-type: none;
    margin: 0 0 1rem 0 !important;
  }

  &__numberal-list {
    margin-bottom: 1rem;
    list-style-type: decimal;
  }
}

.ms-auto {
  a:not(last-child) {
    margin-right: 1rem;
  }
}
