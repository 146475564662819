.conversion-select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex-shrink: 1;
  }

  button, span {
    flex-shrink: 0;
  }
}

.conversion-select {
  min-width: 11.25rem;

  &__option {
    .ant-select-item-option-content {
      white-space: normal;
    }

    &--add-new {
      font-weight: 600;
    }
  }
}
