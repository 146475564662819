.conversion-name {
  padding: 1rem;
  &__unmap-unified {
    background-color: #fcd118;
    color: #222;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-size: 1.25rem;
    }

    .ant-btn-default:disabled {
      background-color: #fff;
    }
  }

  &__switch {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.625rem;
    }
  }

  &__export {
    display: flex;
    justify-content: space-between;
  }

  &__update-names {
    transition: all .3s ease-in-out;

    &:disabled {
      scale: 0;
    }
  }

  &__column-desc {
    ul, ol {
      margin-left: 1.25rem;
    }

    li {
      margin-bottom: 0.625rem;
    }

    br {
      display: block;
      content: "";
      margin-bottom: 0.75rem;
    }

    &__alphabet {
      list-style: lower-alpha;
    }
  }
}
