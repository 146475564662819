.heading-primary {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.125rem;
  letter-spacing: 1px;
  color: var(--color-grey-dark-3);
}

.heading-secondary {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1,75rem;
  line-height: 2.5rem;
  color: var(--color-grey-dark-4);

  &--red {
    color: var(--color-primary);
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 600;
  text-transform: uppercase;
}

.sub-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #2b2d42;
}

.bold-desc {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
}

.desc {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

.merriweather-primary-header {
  font-weight: 700;
  font-size: 3rem;
  line-height: 120%;

  @media only screen and (max-width: $bp-medium) {
    font-size: 2.1875rem;
  }
}

.merriweather-secondary-header {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 132%;
}

// New design typography
.new-heading-primary {
  font-size: 4.125rem;
  font-weight: 700;
  color: #151515;
  line-height: 1;
}

.new-heading-secondary {
  font-size: 2.5rem;
  font-weight: 700;
  color: #151515;
  line-height: 1.2;

  &--semi-bold {
    font-weight: 600;
  }
}

.new-heading-tertiary {
  font-size: 2rem;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: #151515;
  line-height: 1;

  &__bold {
    font-weight: 700;
  }
}

.new-title {
  font-size: 1.75rem;
  letter-spacing: 0.2px;
  font-weight: 600;
  color: #151515;

  &--bold {
    font-weight: 700;
  }
}

.lang-en {
  .new-title {
    font-size: 1.5rem;
  }

}

.new-sub-title {
  font-size: 1.5rem;
  line-height: 1.33;
  color: #151515;

  &--semi-bold {
    font-weight: 600;
  }

  &--bold {
    font-weight: 700;
  }
}

.new-desc {
  font-size: 1.125rem;
  line-height: 1.63;
  letter-spacing: 0.1px;
  color: #151515;

  &--semi-bold {
    font-weight: 600;
  }

  &--bold {
    font-weight: 700;
  }
}

.new-bold-desc {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #151515;

  &--bold {
    font-weight: 700;
  }
}

.form-label {
  font-size: 0.9375rem;
  font-weight: 700;
  margin-bottom: 0.5625rem;
}
