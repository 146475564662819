.campaigns {
  padding: 1rem;
  &__select-columns {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    .ant-select {
      margin-right: 0.625rem;
    }
  }

  &__options {
    flex-grow: 1;
  }

  &__unmapped-count {
    background-color: #fcd118;
    color: #222;
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-size: 1.25rem;
    }
  }
}
