//COLOR
:root {
  --color-primary: #D90429;
  --color-primary-light: #EC0F78;
  --color-primary-dark: #FB1717;
  --color-primary-light-2: #FFE8E8;
  --color-primary-light-3: #FFF3F3;

  --color-grey-light-1: #F8F8F8;
  --color-grey-dark-1: #999;
  --color-grey-dark-2: #666;
  --color-grey-dark-3: #222;
  --color-grey-dark-4: #111;
  --color-grey-dark-5: #373F41;
  --color-grey-border-1: #CCCCCC;
  --color-grey-border-2: #EEEEEE;
  --color-grey-border-3: #DDDDDD;
  --color-grey-border-4: #F5F5F5;

  --color-white: #FFF;

  --color-toast-success: #12B736;
  --color-toast-warning: #faad14;
  --color-toast-error: #ff4d4f;
  --color-complementary-blue: #0066FF;

  // New design variables
  --color-new-primary: #FFCC63;
  --color-new-primary-dark: #494F4D;
  --color-new-secondary-1: #00A9B6;
  --color-new-secondary-2: #FB9193;
  --color-new-secondary-3: #44B5F7;
  --color-new-secondary-4: #22355D;
  --color-new-background: #f9f9f9;
  --color-new-text-button-primary-dark: #151515;
}

// FONT
$default-font-size: 1.6rem;

//BREAK POINTS

$bp-smallest: 30em; // 480px
$bp-small: 36em; // 576px
$bp-medium: 48em; // 768px
$bp-large: 62em; // 992px
$bp-largest: 75em; // 1200px

// z-index
$z-spinner-backdrop: 10000;
$z-toast: 800;
$z-modal: 600;
$z-modal-backdrop: 400;
$z-toggle-nav: 300;
$z-toggle-nav-bg: 299;
$z-header: 100;
