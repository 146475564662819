

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-size: $default-font-size;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #484848;
}

span {
  font-family: 'Inter', sans-serif;
}

a,
a:hover,
a:focus {
  color: #0C111D;
}

.horizontal-divider {
  height: 1px;
  background-color: #ebebeb;
  width: 100%;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #787878;
  font-size: 1rem;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #787878;
  font-size: 1rem;
  opacity: 1;
}

::placeholder {
  color: #787878;
  font-size: 1rem;
  opacity: 1;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-grey-dark-1);
  border-radius: 0.625rem;
  border: 2px solid var(--color-grey-dark-1);
}

p {
  margin: 0;
}
