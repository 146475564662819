// Here you can add other styles
.page-header {
  font-size: 32px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8ed;
  padding-block: 15px;
  padding-inline: 20px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fcd118;
  border-color: #fcd118;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon {
  color: #151515;
}

.u-mb-sm {
  margin-bottom: 12px !important;
}

.u-mb-md {
  margin-bottom: 24px !important;
}

.ant-modal-root .ant-modal-wrap{
  z-index: 10000;
}
