.management-list {
  padding: 1rem;
}

.location {
  &__select-columns {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    .ant-upload-wrapper {
      margin-left: 0.625rem;
    }
  }
}
