.add-connection {
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
}

.new-connection {
  &__config {
    display: flex;
    align-items: flex-start;
    max-width: 600px;
  }

  &__setup-guide {
    box-shadow: -2px 0 10px hsla(241, 51%, 20%, 12%);
    background-color: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px;

    ol {
      line-height: 24px;

      li {
        margin-bottom: 16px;
      }
    }
  }

  &__step {
    flex-grow: 1;
    min-width: 350px;
    padding: 20px;
  }

  .ant-steps {
    // border-bottom: 1px solid #e8e8ed;
    padding-block: 15px;
    padding-inline: 20px;
    flex-grow: 1;
    min-width: 350px;
    padding: 20px;
  }
}

.define-source {
  &__config {
    &__auth {
      display: flex;
      align-items: flex-start;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
  }

  &__oauth-btn {
    border-color: #4285f4;
    border-radius: 8px;
    background: transparent;
    img {
      width: 40px;
      margin-right: 5px;
    }
  }

  &__setup-source {
    margin-left: auto;
    display: flex;
  }

  &__setup-guide {
    padding-inline: 15px;
    padding-block: 15px;
  }

  &__oauth2-button {
    background-color: #fcd118;
    border-radius: 10px;
    border: none;
    color: #151515;
    font-size: 15px;
    line-height: 15px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .ant-select {
    width: 100%;
  }
}

.define-destination {

  .basic-single {
    margin-bottom: 20px;
  }

  .btn {
    margin-left: auto;
  }
}

.lang-json {
  display: block;
  padding: 15px;
  overflow: auto;
  border-radius: 8px;
  border-bottom: 2px solid #e8e8ed;
  white-space: break-spaces;
  background: #f9f9fb;
  font-size: 1em;
  color: #1a194d;
  margin-bottom: 16px;
}
