.button {
  display: flex;
  position: relative;
  border: 1px solid var(--gray-300, #d0d5dd);
  font-size: 1rem;
  cursor: pointer;
  background: var(--base-white, #fff);
  padding: 0.5625rem 1.125rem;
  color: var(--gray-700, #344054);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 8px;

  &--large {
    padding: 1rem 1.75rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  &--full {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &--primary {
    border: 1px solid var(--primary-600, #fcd118);
    background: var(--primary-600, #fcd118);
    color: #344054;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    span{
      z-index: 2;
    }

    &::after {
      position: absolute;
      content: " ";
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
    }

    &:hover {
      // background: var(--primary-600, #fff);
      color: var(--gray-700, #344054) !important;

      &::after(:not(:disabled)) {
        background: #fff !important;
        border-radius: 8px;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  img {
    margin-right: 0.75rem;
  }
  &:disabled {
    cursor: default;
    background-color: #f0f0f1;
    border: 1px solid #98a2b3;
  }
}

.btn-animated {
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: #fcd118;
  }

  &:hover::before,
  &:hover::after {
    width: 100%;
  }
  &:hover span::before,
  &:hover span::after {
    height: 100%;
  }

  span {
    display: block;
    &::before,
    &::after {
      content: "";
      width: 2px;
      height: 0;
      position: absolute;
      transition: all 0.2s linear;
      background: #fcd118;
      border-radius: 1rem;
    }
  }
}

.btn-animated::before {
  left: 50%;
  top: 0;
  transition-duration: 0.4s;
}
.btn-animated::after {
  left: 50%;
  bottom: 0;
  transition-duration: 0.4s;
}
.btn-animated span::before {
  left: 0;
  top: 50%;
  transition-duration: 0.4s;
}
.btn-animated span::after {
  right: 0;
  top: 50%;
  transition-duration: 0.4s;
}
.btn-animated:hover::before,
.btn-animated:hover::after {
  left: 0;
}
.btn-animated:hover span::before,
.btn-animated:hover span::after {
  top: 0;
}
