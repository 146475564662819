.update-multiple-name {
  &__form-item {
    display: flex;

    .ant-checkbox-wrapper {
      margin: 0.3125rem 0.625rem 0 0;
    }

    .ant-form-item {
      flex-grow: 1;
    }
  }
}
