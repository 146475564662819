
.u-mb-sm {
  margin-bottom: 0.75rem !important;
}

.u-mb-md {
  margin-bottom: 1.5rem !important;
}

.u-mb-bg {
  margin-bottom: 2rem !important;
}

.u-mb-ex-bg {
  margin-bottom: 2.5rem !important;
}

.u-mb-hg {
  margin-bottom: 5rem !important;
}

.u-mt-md {
  margin-top: 1.5rem !important;
}

.u-mt-ex-bg {
  margin-top: 2.5rem !important;
}

.u-mt-0 {
  margin-top: 0rem !important;
}

.u-mb-0 {
  margin-bottom: 0rem !important;
}

.u-ml-sm {
  margin-left: 0.9375rem !important;
}

.u-ml-md {
  margin-left: 1.5rem !important;
}

.u-ml-bg {
  margin-left: 2rem !important;
}

.u-ml-ex-bg {
  margin-left: 2.5rem !important;
}

.u-pd-2 {
  @media only screen and (max-width: $bp-smallest) {
    padding: 0 1.25rem !important;
  }
}

.align-page {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 0.9375rem;

  @media only screen and (max-width: $bp-medium) {
    padding: 0 5px;
  }
}

.text-align-right {
  text-align: right;
}

.display-block {
  display: block;
}

.text-uppercase {
  text-transform: uppercase;
}

.while-space-pre-line {
  white-space: pre-line;
}

.waring-icon {
  width: 1.4375rem;
  height: 1.4375rem;
  color: var(--color-white);
  border-radius: 50%;
  text-align: center;
  background: linear-gradient(95.94deg, var(--color-primary-light), var(--color-primary-dark));
}

.u-ps-rl {
  position: relative;
}

.error-message {
  color: #e74c3c;
  font-size: 1rem;
  font-weight: 600;
}

.red-text {
  color: #e74c3c;
}

.black-text {
  color: #484848;
}

.asterisk {
  color: #e74c3c;
}

.underline-text {
  text-decoration: underline;
}
