// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

@import 'connection';

// If you want to add custom CSS you can put it here.
@import 'custom';

@import "abstracts/variables";
@import "abstracts/mixins";

@import "base/base";
@import "base/utilities";
@import "base/typography";

@import "components/button";
@import "components/conversion-select";
@import "components/update-multiple-names";

@import "pages/conversion_name";
@import "pages/campaigns";
@import "pages/management-list";
@import "pages/privacy-policy";
@import "pages/naming-tools";

@import "vendors/ant";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;

  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

.text-yellow {
  color: #ffce34;
}
.text-black {
  color: #232323;
}
.text-grey {
  color: #666666;
}
.text-light {
  color: #fefeff;
}
.text-pink {
  color: #ff3286;
}
.text-error {
  color: #e55353;
}
.text-dark-yellow {
  color: #be7713;
}
.bg-black {
  background-color: #0c0b03 !important;
}
.bg-black-1 {
  background-color: #1a1a1a;
}
.bg-dark-grey {
  background-color: #232323;
}
.bg-grey {
  background: #4a4a4a;
}
.bg-light-grey {
  background: #797979;
}
.bg-light-grey-1 {
  background: #f4f5fc;
}
.bg-light-yellow {
  background: #ffce34;
}
.bg-light {
  background: #f7f8fb;
}
.bg-brown {
  background: #402c0b;
}
.bg-dark-yellow {
  background: #be7713;
}
.bg-dark-grey-2 {
  background: #191919;
}
.fs-56 {
  font-size: 56px;
  line-height: 64px;
}
.fs-22 {
  font-size: 22px;
  line-height: 32px;
}
.fs-46 {
  font-size: 46px;
  line-height: 58px;
}
.fs-40 {
  font-size: 40px;
  line-height: 58px;
}
.fs-36 {
  font-size: 36px;
  line-height: 44px;
}
.fs-24 {
  font-size: 24px;
  line-height: 32px;
}
.fs-20 {
  font-size: 20px;
  line-height: 28px;
}
.fs-14 {
  font-size: 14px;
  line-height: 22px;
}
.fs-16 {
  font-size: 16px;
  line-height: 24px;
}

.fs-48 {
  font-size: 48px;
}

.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}
.navbar-nav .nav-link {
  color: #ffce34;
}
.boder-radius-4 {
  border-radius: 4px;
}

.page-item {
  cursor: pointer;
}

.date-input-dashboard {
  flex: 1;
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.date-input-extraction {
  height: 38px;
  width: 100%;
  border: 1px solid #b1b7c1;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
}
